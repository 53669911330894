import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'style/theme'

const StyledPageAlert = styled.div({
	background: colors.error.background,
	color: colors.error.color,
	padding: '7px 40px',
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	zIndex: 2
})

export const PageAlert = ({ message }) => {
	return (
		<StyledPageAlert>
			{message}
		</StyledPageAlert>
	)
}
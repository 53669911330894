import React from 'react'
import { Progress } from 'antd'
import styled from '@emotion/styled'
import { WidgetSection } from 'components'
import { colors } from 'style/theme'

const StatusStyle = styled.div(props => ({
	fontSize: 22,
	fontWeight: 700,
	color: props.color
}))

export const ScanStatus = ({ progress, color }) => {
	return (
		<WidgetSection>
			{/* <Progress
				type="circle"
				percent={100}
				strokeWidth={7}
				strokeLinecap="square"
				format={() => progress}
			/> */}
			<StatusStyle color={color}>{progress}</StatusStyle>
		</WidgetSection>
	)
}

import React, { useEffect, useState } from 'react'
import {usePayment} from 'context/payment-context';
import { getAuthHeader } from 'utils/auth'
import { apiClient } from 'utils/api-client'
import { useProjects } from 'context/project-context'
import { Button, Dropdown, Menu } from 'antd'
import { notificationToast } from 'components'

export const SCAN_TYPE_SCAN = 'scan'
export const SCAN_TYPE_COMPARE = 'compare'
const PDF = 'Pdf'
const CSV = 'Csv'

export const download = async ({ project, exportId, setDownloading }) => {
  let headers = getAuthHeader()
  try {
    setDownloading && setDownloading(true)
    let result = await apiClient(`/${project.name}/export/download/${exportId}`, {
      headers,
      responseType: 'arraybuffer'
    })
    if (result && result.data) {
      const file = new Blob([result.data], { type: 'application/zip' })
      const fileURL = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = fileURL
      link.download = `uTester_report_"${new Date()}.zip`
      link.click()
      setDownloading && setDownloading(false)
    }
  } catch (error) {
    setDownloading && setDownloading(false)
    error = error.response && error.response.data
    let parsedError = Buffer.from(error).toString('utf8')
    let err =
      parsedError && parsedError !== '' ? JSON.parse(parsedError) : { Errors: 'Error while trying to download file' }
    notificationToast({
      message: 'Error',
      description: `${err.Errors}`,
      icontype: 'alert'
    })
  }
}

const isExportExist = async ({ project, exportId, setExportId, setProcessing }) => {
  let response = null
  const interval = setInterval(async () => {
    if (response && response.data) {
      switch (response.data) {
        case 'Running':
          response = await apiClient(`${project.name}/export/${exportId}`, { headers: getAuthHeader() })
          break
        case 'Succeed':
          clearInterval(interval)
          setProcessing(false)
          setExportId(exportId)
          break
        case 'Failed':
          clearInterval(interval)
          setProcessing(false)
          setExportId(null)
          notificationToast({
            message: 'Error',
            description: 'An Error occurred while trying to export report',
            icontype: 'alert'
          })
          break
      }
    } else {
      response = await apiClient(`${project.name}/export/${exportId}`, { headers: getAuthHeader() })
    }
  }, 1000)
}

const onExport = async ({
  project,
  scanId,
  exportType,
  exportFilters,
  scanContext,
  setExportId,
  setProcessing,
  onExportError
}) => {
  try {
    let body = {
      filters: exportFilters,
      type: exportType
    }

    if (scanContext === SCAN_TYPE_COMPARE && Array.isArray(scanId)) {
      body.scan_a = scanId[0]
      body.scan_b = scanId[1]
    } else {
      body.id = scanId
    }

    let response = await apiClient(`/${project.name}/export/${scanContext}`, {
      method: 'POST',
      body,
      headers: getAuthHeader()
    })
    let exportId = (response && response.data && response.data.id) || null
    if (exportId) {
      setExportId(null)
      setProcessing(true)
      await isExportExist({ project, exportId, setExportId, setProcessing })
    }
  } catch (e) {
    const error = e?.response?.data?.Errors ? e.response.data.Errors[0] : e.message
    onExportError && onExportError(error)
  }
}

export const Export = ({ scanId, exportFilters, scanContext, onExportError, exportEnabled = true }) => {
  const { project } = useProjects()
  const [processing, setProcessing] = useState(false)
  const [exportId, setExportId] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const {isTrialAccount, setPaymentModalOpen} = usePayment();

  useEffect(() => {
    if (exportFilters) {
      setExportId(null)
    }
  }, [exportFilters])
  
  const menu = (
    <Menu
      onClick={({ key: exportType }) =>
        isTrialAccount
        ?
          setPaymentModalOpen(true, `export`)
        :
        onExport({ project, scanId, exportType, exportFilters, scanContext, setExportId, setProcessing, onExportError })
      }
    >
      {/*<Menu.Item key={PDF}>PDF</Menu.Item>*/}
      <Menu.Item key={CSV} disabled={!exportEnabled}>CSV</Menu.Item>
    </Menu>
  )

  return (
    <>
      {!exportId && (
        <Dropdown overlay={menu}>
          <Button loading={processing} type="primary" icon="export">
            {processing ? 'GENERATING ...' : 'EXPORT'}
          </Button>
        </Dropdown>
      )}
      {exportId && (
        <Button
          loading={downloading}
          type="primary"
          icon="download"
          onClick={() => download({ project, exportId, setDownloading })}
        >
          DOWNLOAD
        </Button>
      )}
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { Layout, Menu, Button, Modal, Typography, Popover } from 'antd'
import styled from '@emotion/styled'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import YouTube from 'react-youtube'
import useAxios from 'axios-hooks'
import { getAuthHeader } from 'utils/auth'
import { PageLoading } from 'components'
import { SHOW_TUTORIALS_STORAGE_KEY } from 'utils/constants'

const { Sider, Content } = Layout

const DownloadButton = styled(Button)({
  fontWeight: 800
})

const StlyedMenuUnfoldOutlined = styled(MenuUnfoldOutlined)`
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  :hover {
    color: #1890ff;
  }
`
const StlyedMenuFoldOutlined = styled(MenuFoldOutlined)`
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  :hover {
    color: #1890ff;
  }
`
const StlyedSider = styled(Sider)`
  background: white;
  overflow: auto;
  height: 370px;
  direction: rtl;
  .ant-layout-sider-children{
    direction: ltr;
  }
`

export const Tutorials = () => {
  const [tutorialsDialogVisible, setTutorialDialogVisible] = useState(false)
  const [activeVideo, setActiveVideo] = useState(null)

  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: `/tutorials/get-playlist-items`,
      method: 'GET',
      headers: getAuthHeader(),
      validateStatus: false
    },
    { manual: true }
  )

  var showTutorials = window.localStorage.getItem(SHOW_TUTORIALS_STORAGE_KEY)
  if (showTutorials) {
    fetch()
    setTutorialDialogVisible(true)
    window.localStorage.removeItem(SHOW_TUTORIALS_STORAGE_KEY)
  }

  useEffect(() => {
    if (data) {
      console.log('@data', data)
      setActiveVideo(data[0])
    }
  }, [data])
  return (
    <>
      <DownloadButton
        type="link"
        onClick={() => {
          setTutorialDialogVisible(true)
          if (!data) {
            fetch()
          }
        }}
      >
        <MenuUnfoldOutlined />
        Tutorials
      </DownloadButton>
      <Modal
        centered
        width={1000}
        title="Check1st Tutorials"
        visible={tutorialsDialogVisible}
        onCancel={() => setTutorialDialogVisible(false)}
        footer={[]}
      >
        {activeVideo ? (
          <Layout>
            <StlyedSider width={300} trigger={null}>
              <Menu mode="inline" defaultSelectedKeys={[activeVideo.video_id]}>
                {Object.entries(data).map(([key, videoObject]) => (
                  <Menu.Item
                    onClick={() => {
                      setActiveVideo(videoObject)
                    }}
                    key={videoObject.video_id}
                  >
                    <Popover
                      placement="right"
                      content={
                        <div>
                          <p style={{ 'max-width': '300px' }}>{videoObject.description}</p>
                        </div>
                      }
                      title={videoObject.title}
                    >
                      {videoObject.title}
                    </Popover>
                  </Menu.Item>
                ))}
              </Menu>
            </StlyedSider>
            <Layout style={{ background: 'white' }}>
              <Content
                style={{
                  'margin-left': '10px',
                  background: 'white'
                }}
              >
                <YouTube videoId={activeVideo.video_id} width="720" height="405" frameborder="0" allowfullscreen />
              </Content>
            </Layout>
          </Layout>
        ) : (
          <PageLoading />
        )}
      </Modal>
    </>
  )
}

import React, { Fragment } from 'react'
import { Breadcrumb, Icon } from 'antd'
import styled from '@emotion/styled'
import { navigate } from '@reach/router'

const { Item, Separator } = Breadcrumb
const StyledCrumbs = styled(Breadcrumb)({
  marginLeft: 10,
  fontSize: 14,
  fontWeight: 500,
  alignSelf: 'center'
})

export const PageCrumbs = ({ crumbs, separator = '>', ...rest }) => {
  return (
    <StyledCrumbs separator={separator} {...rest}>
      {crumbs.map((crumb, index) => {
        let { name, icon, path, data, separator } = crumb
        let pointer = !path ? 'auto' : 'pointer'

        return (
          <Fragment key={name}>
            <Item
              style={{ cursor: pointer }}
              onClick={() => {
                if (!path) return
                navigate(path)
              }}
            >
              {icon && <Icon type={icon} />}
              <span>{name}</span>
              {data && <span>{data}</span>}
            </Item>
            {separator && <Separator>{separator}</Separator>}
          </Fragment>
        )
      })}
    </StyledCrumbs>
  )
}

import React, { Component } from 'react'
import { Result } from 'antd'
import { ReactComponent as Error } from 'style/icons/error.svg'
import { notificationToast } from 'components'

const ignoreTimeoutExceptions = (error) => {
	return error && error.message && error.message.includes("timeout") ? null : error;
}
  

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false } 
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.error(error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Result
					status="error"
					icon={<Error width={360} height={360} />}
					title="Something Went Wrong"
					subTitle="Please check and modify the following information before resubmitting."
				/>
			)
		}

		return this.props.children
	}
}

export { ErrorBoundary, ignoreTimeoutExceptions }

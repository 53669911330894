import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import { usePayment } from 'context/payment-context';
import styled from '@emotion/styled'
import FeaturesStep from './FeaturesStep';
import ChargeStep from './ChargeStep';
import useAxios from 'axios-hooks'
import { getAuthHeader } from 'utils/auth'

const StyledModal = styled(Modal)`
    .ant-modal-footer{
        border-top: none !important;
    }
`

const ProcceedButton = styled(Button)`
    margin: 10px;
    margin-top: 20px;
    background: #2747A8; 
    color: white; 
    padding: 0 35px;
`

const PaymentModal = () => {
    const {isPaymentModalOpen, setPaymentModalOpen, paymentModalTrigger} = usePayment();
    const [isFeaturesStep, setIsFeaturesStep] = useState(true);

    const [{ data: tiersData, loading: tiersLoading, error: tiersError }, getTiers] = useAxios(
        {
          method: 'GET',
          url: `/payment/tiers`,
          headers: getAuthHeader()
        }
    )

    const [{ data: configData, loading: configLoading, error: condifgError }] = useAxios({
        url: '/payment/configuration',
        method: 'GET',
        headers: getAuthHeader()
      })

    const onClose = () => {
        setIsFeaturesStep(true)
        setPaymentModalOpen(false)
    }

    const featureName = paymentModalTrigger && paymentModalTrigger.toLowerCase();

    return(
        <StyledModal
            centered
            width='840px'
            title={`To use '${featureName}' you must have paid account`}
            visible={isPaymentModalOpen}
            onCancel={onClose}
            footer={
                isFeaturesStep
                ?
                <ProcceedButton id="procceedButton" onClick={() => setIsFeaturesStep(false)}>PROCEED</ProcceedButton>
                :
                []
            }
        >
            {!isFeaturesStep && configData ? <ChargeStep tiersData={tiersData} setPaymentModalOpen={setPaymentModalOpen} stripePK={configData}/> : <FeaturesStep />}
        </StyledModal>
    )
}

export default PaymentModal;
import styled from '@emotion/styled'
import React from 'react'
import { colors } from 'style/theme'

const StyledScanWidget = styled.div({
	display: 'grid',
	gridTemplateRows: '0 1fr',
	gridTemplateColumns: '1fr',
	justifyItems: 'center',
	gridGap: 20,
	height: '100%'
})

const StyledScanWidgetTitle = styled.h2(props => ({
	justifySelf: 'left',
	color: props.color,
	marginButton: "10px",
	fontSize: '0.9rem'
}))

const StyledScanChartWidget = styled.div({
	display: 'grid',
	gridTemplateRows: '25px 1fr',
	gridTemplateColumns: '1fr',
	height: '100%',
	textAlign: 'center'
})

export const ScanWidget = ({ children }) => {
	return <StyledScanWidget>{children}</StyledScanWidget>
}

export const ScanWidgetTitle = ({ children, color=colors.brandText }) => {
	return <StyledScanWidgetTitle color={color}>{children}</StyledScanWidgetTitle>
}

export const ScanChartWidget = ({ children }) => {
	return <StyledScanChartWidget>{children}</StyledScanChartWidget>
}

export const WidgetSection = styled.div({
	textAlign: 'center',
	'.ant-progress-circle .ant-progress-text': {
		fontWeight: 700,
		fontSize: 26
	},
	alignSelf: "center"
})

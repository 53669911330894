import React from 'react'
import styled from '@emotion/styled'
import { Popover } from 'antd'
import { colors } from 'style/theme'
import { EllipsisTooltip } from 'components'
import { Tooltip } from 'antd'

const StyledMoreItems = styled.div({
	color: colors.brand,
	fontWeight: 700,
	display: 'flex',
	alignItems: 'center'
})

const FirstItem = styled.span({
	marginRight: 10,
	alignSelf: 'center',
	textAlign: 'center',
	fontWeight: 700,
	backgroundColor: colors.brandWhite,
	borderRadius: 5
})

const MoreCount = styled.div({
	backgroundColor: '#F4FAFF',
	border: `1px solid ${colors.brand}`,
	borderRadius: '50%',
	height: 24,
	width: 24,
	lineHeight: '24px',
	textAlign: 'center',
	color: colors.brand,
	fontSize: 10
})

const StyledMoreItemsList = styled.div({
	maxWidth: 500,
	display: 'grid',
	gridTemplateColumns: 'repeat(3, 1fr)',
	justifyContent: 'center',
	alignContent: 'center',
	alignItems: 'start',
	label: {
		alignSelf: 'center',
		textAlign: 'center',
		fontWeight: 700,
		backgroundColor: colors.brandWhite,
		color: colors.brand,
		margin: 5
	}
})


const CompareItemsContainer = styled.div({
	clear: "both",
	paddingBottom: 25,
})

const CompareItemLabel = styled.div((props) => ({
	float: "left",
	color: props.color,
	fontWeight: 700
}))

const CompareItemCount = styled.div((props) => ({
	color: props.color,
	float: "right",
	fontWeight: 700
}))


const MoreItemsList = ({ items, renderItem }) => {
	return (
		items && (
			<StyledMoreItemsList>
				{items.map((item, i) =>
					renderItem ? renderItem(item, i) : <label key={item.id || i}>{item.name || item}</label>
				)}
			</StyledMoreItemsList>
		)
	)
}

function LabelTooltip({selector}){
	return (
		<>
			<EllipsisTooltip>{selector}</EllipsisTooltip> 
		</>
	)
}

export const MoreItems = ({ items, placement = 'top', renderFirst, renderItem, renderCounter }) => {
	const firstItem = items && items.length ? items[0].name || items[0] : null
	const firstItemToolTip = items && items.length ? items[0].tooltip : null
	let moreItems = items && items.length > 1 ? items.slice(1) : []
	return (
		<StyledMoreItems>
			{
				renderFirst 
					? renderFirst(firstItem) 
					: firstItem && (
						(firstItemToolTip 
							? <Tooltip placement="right" title={<LabelTooltip selector={firstItemToolTip}/>}>
								<FirstItem>{firstItem}</FirstItem>
							</Tooltip>
							:
							<FirstItem>{firstItem}</FirstItem>
						)
					)
				}
			{moreItems.length > 0 && (
				<Popover content={<MoreItemsList items={moreItems} placement={placement} renderItem={renderItem} />}>
					{renderCounter ? renderCounter(moreItems.length) : <MoreCount>+{moreItems.length}</MoreCount>}
				</Popover>
			)}
		</StyledMoreItems>
	)
}

export const ShowStyledItems = ({ moreItems, placement = 'top', renderCounter, count }) => {
	return (
		<StyledMoreItems>
			{
				!!moreItems.length 
				?
					<Popover content={<StyledItemsList items={moreItems} placement={placement} count={count} />}>
						{renderCounter ? renderCounter(moreItems.length) : <MoreCount>+{moreItems.length}</MoreCount>}
					</Popover>	
				:
				renderCounter ? renderCounter(moreItems.length) : <MoreCount>+{moreItems.length}</MoreCount>
			}
			
		</StyledMoreItems>
	)
}

const StyledItemsList = ({ items, count }) => {
	return (
		items && (
			<div style={{minWidth: "200px"}}>
				{items.map((item, i) =>
					<CompareItemsContainer>
						<CompareItemLabel color={item.color}>{item.error}</CompareItemLabel>
						<CompareItemCount color={item.color}>{item.count || count}</CompareItemCount>
					</CompareItemsContainer>
				)}
			</div>
		)
	)
}

import React from 'react'
import styled from '@emotion/styled'
import { Credits } from 'components'
import { Popover } from 'antd'
import { UserSettings, UserSettingsMenu } from 'components/UserSettings'
import { Notifications } from 'components/Notifications'
import { hasPermission } from 'utils/hasPermission'
import { USER, ADMIN } from 'utils/constants'
import { UrecorderDownload } from 'components/UrecoderDownload';
import PaymentModal from 'views/Payment';
import { Tutorials } from "../Tutorials/Tutorials"
import { TRIAL, PAYG, ENTERPRISE } from 'utils/constants'

const StyledUserSettings = styled.div(props => ({
  justifySelf: 'end',
  marginRight: 20,
  width: props.enterprise && !props.isRoleUser ? 540 : 400,
  display: 'grid',
  justifyItems: 'end',
  alignItems: 'center',
  gridTemplateColumns: props.gridColumns
}))

const ClickableSettings = styled.span({
  cursor: 'pointer'
})

const gridCalculateByPermission = (isRoleUser, accountType) => {
  if(accountType === ENTERPRISE) {
    if (isRoleUser) {
      return '1.5fr 2fr 1fr 1fr';
    }
    else {
      return '1fr 3fr 1fr 1fr 2fr';
    }
  }else {
    return '1.5fr 2fr 1fr 1fr';
  }
}

const UserArea = ({ user, accounts, changeAccount, logout }) => {
  const accountType = user.account_type;
  let isAccountAdmin = hasPermission([user.account_role], [ADMIN]);
  let isRoleUser = hasPermission([user.account_role], [USER]);
  let gridColumns = gridCalculateByPermission(isRoleUser, user.account_type);

  return (
    <StyledUserSettings enterprise={accountType === ENTERPRISE} isRoleUser={isRoleUser} gridColumns={gridColumns}>
      <Tutorials />
      <PaymentModal />
      <UrecorderDownload />
      {isAccountAdmin && accountType === ENTERPRISE && <Credits user={user} />}
      <Notifications />
      <Popover content={<UserSettingsMenu user={user} accounts={accounts} changeAccount={changeAccount} logout={logout} />} trigger="click" placement="bottomRight">
        <ClickableSettings>
          <UserSettings user={user} />
        </ClickableSettings>
      </Popover>
    </StyledUserSettings>
  )
}

export default UserArea

import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'antd'
import { colors } from 'style/theme'

const directions = {
	even: { type: 'minus', color: colors.brandText, symbol: '' },
	up: { type: 'rise', color: colors.success.color, symbol: '+' },
	down: { type: 'fall', color: colors.error.color, symbol: '-' }
}
const StyledTrend = styled.div({
	display: 'flex',
	alignItems: 'center'
})

const TrendIcon = styled(Icon)(({ direction }) => ({
	color: directions[direction].color,
	fontSize: 24,
	marginRight: 4
}))

const TrendValue = styled.strong({
	fontSize: 16,
	marginRight: 4
})

const TrendLabel = styled.label({
	fontSize: 12,
	color: colors.brandGray
})

export const Trend = ({ direction = 'even', value = 0, valueFormat = '%', label = '' }) => {
	const trendIcon = directions[direction].type
	const TrendValueComponent = React.isValidElement(value) ? (
		value
	) : (
		<TrendValue>
			{directions[direction].symbol}
			<span>
				{value}
				{valueFormat}
			</span>
		</TrendValue>
	)
	return (
		<StyledTrend>
			<TrendIcon type={trendIcon} direction={direction} />
			{TrendValueComponent}
			<TrendLabel>{label}</TrendLabel>
		</StyledTrend>
	)
}

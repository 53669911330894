import React from 'react'
import { notification, Icon } from 'antd'

/*
    @param details: object with the following properties from ant design
    @message - type 'Error', 'success'
    @description - "error message"
    @iconType - close,check
    @iconStyle - css
*/
export const notificationToast = (details) => {
    
    notification.config({
        placement: 'bottomRight',
    });

    const color = details.message  &&  details.message.toLowerCase() === "success" ? "green" : "red";

	notification.open({
		message: details.message,
		description: details.description,
		icon: <Icon type={details.icontype} style={{ color: color }} />
	})
}
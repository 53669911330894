import React from 'react'
import styled from '@emotion/styled'
import { Progress } from 'antd'
import { WidgetSection } from 'components'
import { red, deepOrange, grey, amber, lime, lightGreen, green } from 'material-ui-colors'

export const SCORES =
	{
		'A':[green['400'], green['700']],
		'B':[lightGreen['400'], lightGreen['700']],
		'C':[lime['400'], lime['700']],
		'D':[amber['400'], amber['700']],
		'F':[red['400'], red['700']],
		'Z':[grey['400'], grey['700']]
	}

const ScoreProgress = styled.div(props => ({
	'.ant-progress-circle .ant-progress-text': {
		color: props.labelcolor || '#31A748',
		fontWeight: props.fontWeight || 1000,
		fontSize: props.labelsize || 34,
	},
	style: props.style
}))

export const ScanScore = ({ score, thickness = 60, width=100, labelSize, style, fontWeight }) => {
	let scoreData
	if (score) {
		scoreData = {
			value: score.score || score,
			colors: SCORES[score.score || score]
		}
	}

	if(!scoreData) {
		scoreData = {
			value: '-',
			colors: SCORES["Z"]
		}
	}

	return (
		<WidgetSection>
			<ScoreProgress style={style} labelcolor={scoreData.colors[1]} labelsize={labelSize} fontWeight={fontWeight}>
				<Progress
					type="circle"
					percent={100}
					format={() => scoreData.value}
					width={width}
					strokeWidth={thickness}
					strokeLinecap="square"
					strokeColor={scoreData.colors[1]}
				/>
			</ScoreProgress>
		</WidgetSection>
	)
}

import React, { useContext, createContext } from 'react'
import { useAuth } from 'context/auth-context'

const GlobalConfigContext = createContext()

function GlobalConfigProvider({ children }) {
	const { config } = useAuth()
	return (
		<GlobalConfigContext.Provider value={config}>
			{children}
		</GlobalConfigContext.Provider>
	)
}

function useGlobalConfig() {
	const context = useContext(GlobalConfigContext)
	if (context === undefined) {
		throw new Error(`useGlobalConfig must be used within a GlobalConfigProvider`)
	}
	return context
}



export { GlobalConfigProvider, useGlobalConfig }

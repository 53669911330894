import styled from '@emotion/styled'

export const Ellipsis = styled.div(({ width }) => ({
	width: width || 600,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	fontSize: 14
}))

 export const EllipsisTooltip = styled.div({
	wordWrap: 'break-word',
	wordBreak: 'break-word'
})
import React from 'react'
import styled from '@emotion/styled'
import AdministrationMenu from './AdministrationMenu'
import MenuItem from './MenuItem'
import { DASHBOARD_PERMISSION, TRIAL } from 'utils/constants'
import { hasPermission } from 'utils/hasPermission'
import {usePayment} from 'context/payment-context';

const StyledAppMenu = styled.nav({
  padding: 0,
  display: 'grid',
  gridTemplateRows: '3.5fr 0.5fr',
  height: '100vh'
})

export const AppMenu = ({ user }) => {
  const {setPaymentModalOpen, isTrialAccount} = usePayment();
  const allowDashboard = isTrialAccount || hasPermission(user.permissions, DASHBOARD_PERMISSION);
  return (
    <StyledAppMenu>
      <ul>
        {allowDashboard && <MenuItem setPaymentModalOpen={setPaymentModalOpen} isTrialAccount={isTrialAccount} text="DASHBOARD" icon="appstore" path="/dashboard" />}
        {hasPermission([user.account_role], ['user', 'admin', TRIAL]) && (
          <MenuItem text="SCAN SETTINGS" icon="bars" path="/scan-settings" />
        )}
        {hasPermission([user.account_role], ['admin', TRIAL]) && (
          <MenuItem text="ACCOUNT MANAGEMENT" setPaymentModalOpen={setPaymentModalOpen} isTrialAccount={isTrialAccount} icon="team" path="/account-management" />
        )}
        {user.sys_admin && <AdministrationMenu />}
      </ul>
      <ul>
        <MenuItem text="CONTACT SUPPORT" mailTo path="mailto:service@user1st.com" icon="mail" />
        <div></div>
      </ul>
    </StyledAppMenu>
  )
}

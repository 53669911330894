import moment from 'moment'
import shortid from 'shortid'
import numeral from 'numeral'

const abbr = {
	milliseconds: 'ms',
	seconds: 's'
}

export const timeDiff = (time1, time2, unitOfTime = 'seconds') => {
	let a = moment(time1)
	let b = moment(time2)
	return `${a.diff(b, unitOfTime, true)}${abbr[unitOfTime]}`
}

export const toLocalTime = time => {
	let utc = moment.utc(time)
	return utc.local()
}

export const formatNumber = (value = 0) => {
	return numeral(value).format('0a')
}

export const formatNumberIntl = (value = 0) => {
	const formatter = new Intl.NumberFormat(['en-US', 'en-UK'])
	return formatter.format(value)
}

export const uid = () => shortid.generate()


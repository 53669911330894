import React from 'react'
//import CountUp from 'react-countup'
import styled from '@emotion/styled'
import { formatNumber } from 'utils/formatters'
import { ContentContainer, ScanWidgetTitle, Trend } from 'components'
import { colors } from 'style/theme'

const StyledStatValue = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative'
})

const Value = styled.label(props => ({
	fontSize: '2rem',
	fontWeight: 700,
	':before': {
		content: "''",
		height: 35,
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		left: 0,
		width: 5,
		borderRadius: 50,
		backgroundColor: props.markerColor
	}
}))

const Pad = styled.div({
	padding: '1rem'
})

const CurrentValueStyle = styled.span(props =>({
	color: props.color ? props.color : colors.error.color,
	style: props.style
}))


export const CountValue = ({ value, markerColor, totalErrors, style }) => {
	let showRatio = totalErrors && totalErrors !== value ? true : false
	return (
		<StyledStatValue>
			<Value markerColor={markerColor}>
				<CurrentValueStyle style={style} color={markerColor}>{formatNumber(value)}</CurrentValueStyle>
				{ showRatio &&
					(
						<>
							<span>/</span> 
							<span style={{color: colors.brandDark}}>{totalErrors}</span>
						</>
					)	
				}
			</Value>
		</StyledStatValue>
	)
}

export const TrendCount = ({
	title = '',
	titleColor = colors.brandText,
	countValue = 0,
	markerColor = colors.brandText,
	trendDirection = 'even',
	trendValue = null,
	trendLabel = '',
	totalErrors,
	style
}) => {
	trendValue = trendValue && trendValue != 0 ? trendValue : ""
	return (
		<ContentContainer pad="none">
			<Pad>
				<ScanWidgetTitle color={titleColor}>{title}</ScanWidgetTitle>
			</Pad>
			<CountValue style={style} value={countValue} markerColor={markerColor} totalErrors={totalErrors}/>
			{trendValue && (
				<Pad>
					<Trend direction={trendDirection} value={trendValue} label={trendLabel} />
				</Pad>
			)}
		</ContentContainer>
	)
}

import React from 'react'
import { AuthProvider } from './auth-context'
import { UserProvider } from './user-context'
import { AccountProvider } from './account-context'
import { GlobalConfigProvider } from './config-context'
import { ProjectProvider } from './project-context'
import {PaymentProvider} from './payment-context';

export const AuthenticatedAppProviders = ({ children }) => {
	return (
		<GlobalConfigProvider>
			<AccountProvider>
				<PaymentProvider>
					<ProjectProvider>{children}</ProjectProvider>
				</PaymentProvider>
			</AccountProvider>
		</GlobalConfigProvider>
	)
}

function AppProviders({ children }) {
	return (
		<AuthProvider>
			<UserProvider>{children}</UserProvider>
		</AuthProvider>
	)
}
export default AppProviders



import styled from '@emotion/styled'
import { Alert as BaseAlert } from 'antd'
import { colors, elements } from 'style/theme'

export const StyledAlert = styled(BaseAlert)(props => ({
	border: 'none',
	borderRadius: props.radius || elements.brandBorderRadius,
	[`&.ant-alert-${props.type}`]: {
		'.ant-alert-description': {
			color: '#000000',
			fontSize: props.fontSize || 13,
			fontWeight: props.fontWeight || 400
		},
		'.ant-alert-message':{
			color: colors[props.type].color,
			fontSize: props.fontSize + 2 || 15,
			fontWeight: props.fontWeight || 700
		}
	}
}))

export const StyledSuccessAlert = styled(BaseAlert)(props => ({
	border: 'none',
	borderRadius: props.radius || elements.brandBorderRadius,
	'&.ant-alert-success': {
		'.ant-alert-message, .ant-alert-description': {
			color: colors.success.color,
			fontSize: props.fontSize || 14,
			fontWeight: props.fontWeight || 700
		}
	}
}))


import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import TimeAgo from 'react-timeago'
import { navigate } from '@reach/router'
import { colors, elements } from 'style/theme'
import { Icon } from 'antd'
import { AvatarIcon, NoData } from 'components'
import { useProjects } from 'context/project-context'
import { download } from '../Export'

let icons = {
  info: { type: 'info', bgColor: colors.iconInfo.background, color: colors.iconInfo.color },
  success: { type: 'check', bgColor: colors.iconSuccess.background, color: colors.iconSuccess.color },
  export: { type: 'check', bgColor: colors.iconSuccess.background, color: colors.iconSuccess.color },
  error: { type: 'close', bgColor: colors.iconError.background, color: colors.iconError.color }
}

const StyledNotificationList = styled.ul({
  minWidth: 500,
  maxHeight: 400,
  overflowY: 'auto',
  padding: '0 20px'
})

const NotificationItem = styled.li({
  padding: '15px 0',
  display: 'flex',
  alignItems: 'center',
  borderTop: elements.brandBorder
})

const NotificationText = styled.span(props => ({
  fontWeight: props.isRead ? 400 : 600,
  marginLeft: 10,
  cursor: 'pointer',
  color: colors.brandGray
}))

const Text = styled.div({
  color: colors.brandText,
  fontSize: 12
})
const NotificationItemDelete = styled(Icon)({
  color: colors.brandGray,
  fontSize: 11,
  marginLeft: 'auto',
  cursor: 'pointer'
})

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 0 20px'
})
const Title = styled.h2({
  fontSize: 16,
  fontWeight: 600
})
const ClearAll = styled.a({
  marginLeft: 'auto'
})

const RenderNotificationText = ({ message, type, scan_settings_id, scan_id, exportId, project }) => {
  return (
    <>
      {type === 'export' ? (
        <Text>
          {message} <a onClick={async () => await download({ project, exportId })}>Download</a>
        </Text>
      ) : (
        <Text>
          {message}{' '}
          <a onClick={() => navigate(`/scan-settings/${scan_settings_id}/scan/${scan_id}`, { replace: true })}>View</a>
        </Text>
      )}
    </>
  )
}

export const NotificationList = ({ notifications, markAsRead, batchDiscard, clearAll }) => {
  const { project } = useProjects()
  return (
    <Fragment>
      <Header>
        <Title>Notifications</Title>
        {notifications.length > 0 && <ClearAll onClick={clearAll}>Clear All</ClearAll>}
      </Header>
      {!notifications.length && <NoData text="No Notifications" icon="notification" size={20} />}
      {notifications && (
        <StyledNotificationList>
          {notifications.map(n => {
            let { id, message, type, create_date, scan_settings_id, scan_id, export_id } = n
            return (
              <NotificationItem key={id}>
                <AvatarIcon icon={icons[type].type} size={30} color={icons[type].color} bgcolor={icons[type].bgColor} />
                <NotificationText>
                  <RenderNotificationText
                    message={message}
                    type={type}
                    scan_settings_id={scan_settings_id}
                    scan_id={scan_id}
                    exportId={export_id}
                    project={project}
                  />
                  <TimeAgo date={create_date} />
                </NotificationText>
                <NotificationItemDelete type="close" onClick={() => batchDiscard(id)} />
              </NotificationItem>
            )
          })}
        </StyledNotificationList>
      )}
    </Fragment>
  )
}

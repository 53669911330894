import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Icon, Badge, Popover } from 'antd'
import { elements } from 'style/theme'
import { NotificationList } from 'components/Notifications/NotifictionList'
import useAxios from 'axios-hooks'
import { getAuthHeader } from 'utils/auth'
import {usePayment} from 'context/payment-context';

const StyledNotifications = styled.div({
	width: 70,
	borderLeft: elements.brandBorder,
	borderRight: elements.brandBorder,
	textAlign: 'center',
	justifySelf: 'center'
})

const NotificationsIcon = styled(Icon)({
	fontSize: 20
})

export const Notifications = () => {
	const {isTrialAccount, setPaymentModalOpen} = usePayment();
	const [count, setCount] = useState(0)
	const [notifications, setNotifications] = useState([])
	const [discards, setDiscards] = useState([])

	const [{ data = []}, fetchNotifications] = useAxios(
		{
			method: 'GET',
			url: `/notifications`,
			headers: getAuthHeader()
		},
		{
			manual: true
		}
	)

	const [{loading: deleteLoading}, discardNotifications] = useAxios(
		{
			method: 'DELETE',
			url: `/notifications`,
			headers: getAuthHeader()
		},
		{
			manual: true
		}
	)

	useEffect(() => {
		if(isTrialAccount) return; 
			
		const interval = setInterval(() => {
			if(!deleteLoading) {
				fetchNotifications({timeout: 3000}).catch(() => {});
				if (data) {
					let newItems = data.filter(item => !item.isRead)
					setCount(newItems.length || null)
					setNotifications(data)
				}
			}
		}, 3000)
		return () => {
			clearInterval(interval)
			if (discards.length) {
				discard(discards)
			}
		}
	}, [data, discards, fetchNotifications])

	const discard = discards => {
		discardNotifications({
			data: {
				ids: discards
			}
		})
	}

	const clearAll = () => {
		setCount(0)
		discardNotifications({
			data: {
				ids: notifications.map(n => n.id)
			}
		})
		setNotifications([])
	}

	const updateCount = () => {
		if(count > 0) setCount(count - 1)
	}

	const batchDiscard = id => {
		updateCount()
		let ids = [...discards, id];
		discard(ids)
		setDiscards([])
		setNotifications(notifications.filter(n => n.id !== id))
	}

	
	return (
		<>
			{
				isTrialAccount
				?
				<StyledNotifications onClick={() => setPaymentModalOpen(true, "notification")}>
					<NotificationsIcon type="bell" />
				</StyledNotifications>
				:
				<Popover
					content={
						<NotificationList
						notifications={notifications}
						batchDiscard={batchDiscard}
						clearAll={clearAll}
						/>
					}
					trigger="click"
					placement="topLeft"
				>
					<StyledNotifications>
						<Badge count={count} offset={[2, -3]}>
							<NotificationsIcon type="bell" />
						</Badge>
					</StyledNotifications>
				</Popover>
			}
		</>
	)
}

import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import useAxios from 'axios-hooks'
import { colors, elements, layout } from '../../style/theme'
import { Link } from '@reach/router'
import utester from 'style/images/check1st.svg'
import { useUser } from 'context/user-context'
import { useAccount } from 'context/account-context'
import { useProjects } from 'context/project-context'
import { useAuth } from 'context/auth-context'
import UserArea from 'components/AppHeader/UserArea'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'utils/constants'
import { PROJECT_STORE_KEY } from 'context/project-context'
import { getAuthHeader } from 'utils/auth'
import { notificationToast } from 'components'
import { Select } from 'antd'
const { Option } = Select

const StyledAppHeader = styled.div({
  height: layout.topHeaderHeight,
  background: colors.brandWhite,
  borderBottom: elements.brandBorder,
  position: 'fixed',
  width: '100%',
  zIndex: 3,
  display: 'grid',
  gridTemplateColumns: `${layout.sideNavWidth}px 70px 1fr 530px`,
  alignItems: 'center',
  filter: 'drop-shadow(10px 0px 5px #999)'
})

const SideBarBrand = styled.div({
  paddingLeft: 15,
  span: {
    fontSize: 24,
    margin: '10px 0 0 10px',
    verticalAlign: 'middle'
  }
})

const StyledLogo = styled.img({
  height: '40px'
})

const ProjectSelect = styled(Select)({
  div: {
    border: '0px',
    borderRadius: '0px'
  }
})

const ProjectPrefix = styled.div({
  fontSize: '0.8rem',
  fontWeight: '700'
})

export const AppHeader = () => {
  const user = useUser()
  const accounts = useAccount()
  const { setProject, project, projects } = useProjects()
  const { logout } = useAuth()
  let logoLink = '/scan-settings'

  const [
    { data: changeAccountData, loading: changeAccountLoading, error: changeAccountError },
    changeAccountApi
  ] = useAxios(
    {
      method: 'POST',
      url: `/account/change-account`,
      headers: getAuthHeader(),
      validateStatus: false
    },
    { manual: true }
  )

  const changeAccount = (id) => {
    changeAccountApi({ data: { account_id: id } })
  }

  const changeProject = (v, opt) => {
    setProject({ id: opt.key, name: v })
  }

  useEffect(() => {
    if (changeAccountData?.Errors && changeAccountData.Errors.length) {
      notificationToast({
        message: 'Cannot change account',
        description: `${changeAccountData.Errors.join()}`,
        icontype: 'alert'
      })
    } else if (changeAccountData) {
      window.localStorage.setItem(ACCESS_TOKEN_KEY, changeAccountData.access_token)
      window.localStorage.setItem(REFRESH_TOKEN_KEY, changeAccountData.refresh_token)
      window.localStorage.removeItem(PROJECT_STORE_KEY)
      window.location = '/scan-settings'
    }
  }, [changeAccountData])

  return (
    <StyledAppHeader>
      <SideBarBrand>
        <Link to={logoLink}>
          <StyledLogo src={utester} />
        </Link>
      </SideBarBrand>
      <ProjectPrefix>PROJECT:</ProjectPrefix>
      <ProjectSelect defaultValue={project.name} style={{ width: '15rem' }} onChange={(v, opt) => changeProject(v, opt)}>
        {projects.map((item) => (
          <Option key={item.id} value={item.name}>
            {item.name}
          </Option>
        ))}
      </ProjectSelect>
      <UserArea user={user} accounts={accounts} changeAccount={changeAccount} logout={logout} />
    </StyledAppHeader>
  )
}

import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { getAuthHeader, setUser } from 'utils/auth'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Col, Row, Button, Alert, Checkbox, Modal, notification, Icon } from 'antd'
import styled from '@emotion/styled'
import { notificationToast } from 'components'
import {useAuth} from 'context/auth-context';
import { useUser } from 'context/user-context'
import { useAccount } from 'context/account-context'
import { PAYG, TRIAL } from 'utils/constants'
import { navigate } from '@reach/router'
import { SHOW_TUTORIALS_STORAGE_KEY } from 'utils/constants'


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
      height: 40,
      border: '2px solid black'
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

const StyledForm = styled.form`
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }
`

const FormGroup = styled.fieldset`
  margin-bottom: 20px;
  margin-right: 15px;
  padding: 0;
  border-style: none;
  will-change: opacity, transform;
  box-shadow: 0 0 3px #a7a4a4;
  border-radius: 4px;
`

const FormRow = styled.fieldset`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
`

const CheckoutForm = ({ setPaymentModalOpen }) => {
  const user = useUser()
  const accounts = useAccount()
  const [stripeLoading, setStripeLoading] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const { reloadUserInfo, pendingUserInfo } = useAuth()
  const [error, setError] = useState({
    cardNubmer: null,
    expiry: null,
    cvc: null
  })

  const stripe = useStripe()
  const elements = useElements()

  const [
    { data: subscriptionData, loading: subscriptionLoading, error: subscriptionError },
    createSubscription
  ] = useAxios(
    {
      method: 'POST',
      url: `/payment/create`,
      headers: getAuthHeader()
    },
    { manual: true }
  )

  const [
    { data: changeAccountData, loading: changeAccountLoading, error: changeAccountErrror },
    changeAccountApi
  ] = useAxios(
    {
      method: 'POST',
      url: `/account/change-account`,
      headers: getAuthHeader(),
      validateStatus: false
    },
    { manual: true }
  )

  const [
    { data: paymentRequiredData, loading: paymentRequiredLoading, error: paymentRequiredError },
    paymentRequired
  ] = useAxios(
    {
      method: 'POST',
      url: `/payment/payment-require`,
      headers: getAuthHeader()
    },
    { manual: true }
  )

  const [{ data: editPaymentData, loading: editPaymentLoading, error: editPaymentError }, editPayment] = useAxios(
    {
      method: 'PUT',
      url: `/payment/edit`,
      headers: getAuthHeader()
    },
    { manual: true }
  )

  const cardNumberErrors = (data) =>
    data.error ? setError({ ...error, cardNubmer: data.error.message }) : setError({ ...error, cardNubmer: null })
  const cardExpiryErrors = (data) =>
    data.error ? setError({ ...error, expiry: data.error.message }) : setError({ ...error, expiry: null })
  const cardCvcErrors = (data) =>
    data.error ? setError({ ...error, cvc: data.error.message }) : setError({ ...error, cvc: null })

  const handleSubmit = async (event) => {
    event.preventDefault()
    setStripeLoading(true)
    const card = elements.getElement(CardNumberElement)
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: card
    })
    switch (user.account_type) {
      case TRIAL:
        result.paymentMethod && createSubscription({ data: { payment_method_id: result.paymentMethod.id } })
        break
      case PAYG:
        if (user.payg_subscription_status == 'active')
          result.paymentMethod && editPayment({ data: { payment_method_id: result.paymentMethod.id } })
        if (user.payg_subscription_status == 'canceled')
          result.paymentMethod && createSubscription({ data: { payment_method_id: result.paymentMethod.id } })
        if (user.payg_subscription_status == 'unpaid')
          result.paymentMethod && paymentRequired({ data: { payment_method_id: result.paymentMethod.id } })
        break
    }
    setStripeLoading(false)
  }

  useEffect(() => {
    if (subscriptionData) {
      let currentAccount = accounts.filter((account) => account.current)[0]
      changeAccountApi({ data: { account_id: currentAccount.id } })
    }
  }, [subscriptionData])

  useEffect(() => {
    if (paymentRequiredData) {
      let currentAccount = accounts.filter((account) => account.current)[0]
      changeAccountApi({ data: { account_id: currentAccount.id } })
    }
  }, [paymentRequiredData])

  useEffect(() => {
    if (changeAccountData) {
      setUser({ data: changeAccountData })
      reloadUserInfo()
      setPaymentModalOpen && setPaymentModalOpen(false)
      navigate('/scan-settings')
      window.localStorage.setItem(SHOW_TUTORIALS_STORAGE_KEY, true)
      notification.open({
        message: 'Success',
        description: 'Thank you. You have successfully subscribed to pay-as-you-go Check1st, now you can experience full power of Check1st.',
        icon: <Icon type='check' style={{ color: 'green' }} />,
        placement: 'bottomRight',
        duration: 10
      })
    }
  }, [changeAccountData])

  useEffect(() => {
    if (editPaymentData) {
      notificationToast({
        message: 'Success',
        description:
          'Card information updated successfuly!',
        icontype: 'check'
      })
      setPaymentModalOpen(false)
    }
  }, [editPaymentData])

  useEffect(() => {
    if (subscriptionError) {
      notificationToast({
        message: 'Error',
        description: `Cannot save credit card. ${subscriptionError?.response?.data?.Errors}`,
        icontype: 'alert'
      })
    }
  }, [subscriptionError])

  useEffect(() => {
    if (editPaymentError) {
      notificationToast({
        message: 'Error',
        description: `Cannot edit credit card. ${editPaymentError?.response?.data?.Errors}`,
        icontype: 'alert'
      })
    }
  }, [editPaymentError])

  const errorMsg = editPaymentError?.response?.data?.Errors || subscriptionError?.response?.data?.Errors

  return (
    <>
      <StyledForm>
        {errorMsg && <Alert style={{ marginBottom: 10 }} message={errorMsg} type="error" />}
        <div>
          <label for="cardNumber">CREDIT CARD NUMBER</label>
          <FormGroup>
            <FormRow style={{ height: 40 }}>
              <CardNumberElement id="cardNumber" options={CARD_ELEMENT_OPTIONS} onChange={cardNumberErrors} />
              <div className="card-errors" role="alert">
                {error.cardNubmer}
              </div>
            </FormRow>
          </FormGroup>
        </div>
        <Row>
          <Col span={12}>
            <label for="cardExpiry">EXPIRATION (MM/YY)</label>
          </Col>
          <Col span={12}>
            <label for="cardCvc">CREDIT CARD SECURITY CODE</label>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormGroup>
              <FormRow>
                <CardExpiryElement id="cardExpiry" options={CARD_ELEMENT_OPTIONS} onChange={cardExpiryErrors} />
                <div className="card-errors" role="alert">
                  {error.expiry}
                </div>
              </FormRow>
            </FormGroup>
          </Col>
          <Col span={12}>
            <FormGroup>
              <FormRow>
                <CardCvcElement id="cardCvc" options={{ height: 30 }} onChange={cardCvcErrors} />
                <div className="card-errors" role="alert">
                  {error.cvc}
                </div>
              </FormRow>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginBottom: '.5rem' }}>
          <Checkbox onChange={(e) => setTermsChecked(e.target.checked)}>
            By clicking 'subscribe' you agree to our{' '}
            <Button style={{ padding: '0' }} type="link" onClick={() => setShowTerms(true)}>
              Terms of Service
            </Button>
          </Checkbox>
        </Row>
        <Row>
          <Button
            id="subscribeButton"
            disabled={!termsChecked}
            loading={stripeLoading || subscriptionLoading}
            type="primary"
            style={{ width: '9rem' }}
            onClick={handleSubmit}
          >
            SUBSCRIBE
          </Button>
        </Row>
      </StyledForm>
      <Modal
        title="Terms of Service"
        visible={showTerms}
        onOk={() => setShowTerms(false)}
        okText="CLOSE"
        onCancel={() => setShowTerms(false)}
        footer={[
          <Button key="back" style={{margin: '.5rem'}} type="primary" onClick={() => setShowTerms(false)}>
            CLOSE
          </Button>,
        ]}
      >
        <p>Under construction</p>
      </Modal>
    </>
  )
}

export default CheckoutForm

import React from 'react'
import styled from '@emotion/styled'
import { layout } from '../../style/theme'

const StyledAppLayout = styled.div({
	display: 'grid',
	gridTemplateColumns: `${layout.sideNavWidth}px 1fr`,
	minHeight: '100vh',
	paddingTop: layout.topHeaderHeight
})

export const AppLayout = ({ children }) => {
	return <StyledAppLayout>{children}</StyledAppLayout>
}

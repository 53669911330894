import React from 'react'
import styled from '@emotion/styled'

const StyledAppMain = styled.main({
  padding: '2.5rem',
  gridColumnStart: 2,
  position: 'relative'
})

export const AppMain = ({ children }) => {
  return (
    <StyledAppMain>
      {children}
    </StyledAppMain>
  )
}
import React, { useState, useContext, useLayoutEffect } from 'react'
import { useAsync } from 'react-async'
import { bootstrapAppData } from '../utils/bootstrap'
import * as authClient from '../utils/auth'
import { FullPageSpinner } from 'components'
import { navigate } from '@reach/router'

const AuthContext = React.createContext()

function AuthProvider(props) {
	const [firstAttemptFinished, setFirstAttemptFinished] = useState(false)
	const { data = { user: null, accounts: [], projects: [], config: {} }, error, isRejected, isPending, isSettled, reload } = useAsync(
		{
			promiseFn: bootstrapAppData
		}
	)
	let userRole = null;

	useLayoutEffect(() => {
		if (isSettled) {
			setFirstAttemptFinished(true)
		}
	}, [isSettled])

	if (!firstAttemptFinished) {
		if (isPending) {
			return <FullPageSpinner />
		}
		if (isRejected) {
			return (
				<div style={{ color: 'red' }}>
					<p>Uh oh... There's a problem. Try refreshing the app.</p>
					<pre>{error.message}</pre>
				</div>
			)
		}
	}

	const login = form => authClient.login(form)
		.then((response) => {
			userRole = response.account_role;
			reload(response);
		})
		.then(() => {
			var match = RegExp('[?&]return_url=([^&]*)').exec(window.location.search)
			var redirect = (match && decodeURIComponent(match[1].replace(/\+/g, ' '))) ?? "/scan-settings"
			navigate(`${redirect}`);
		})

	const logout = () => authClient.logout().then(reload).then(navigate('/sign-in'))

	return <AuthContext.Provider value={{ ...data, login, logout, reloadUserInfo: reload, pendingUserInfo: isPending }} {...props} />
}

function useAuth() {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`)
	}
	return context
}
export { AuthProvider, useAuth }

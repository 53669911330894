export const ACCESS_TOKEN_KEY = 'ut_a_t'
export const REFRESH_TOKEN_KEY = 'ut_r_t'
export const ACCOUNT_ROLE = 'ut_a_r'
export const CREDITS_PULL_INTERVAL = 10000
export const USER = 'user';
export const ADMIN = 'admin';
export const TRIAL = 'Trial';
export const PAYG = 'PAYG'
export const DASHBOARD_STORAGE_KEY = "dashboard";
export const SHOW_TUTORIALS_STORAGE_KEY = "tutorials_on";

export const ENTERPRISE = 'Enterprise'

export const USER_PERMISSIONS_DICTIONARY = {
    NONE: 'None',
    RUN_SCAN: "Run scans",
    ADMIN: "Admin",
    MANAGE_SCANS: "Manage scans",
    EXPORT_REPORT: "Export report",
    VIEW_DASHBOARD: "View dashboard"
}

export const RUN_SCAN_PERMISSION = [USER_PERMISSIONS_DICTIONARY.ADMIN, USER_PERMISSIONS_DICTIONARY.RUN_SCAN];
export const MANAGE_SCAN_PERMISSION = [USER_PERMISSIONS_DICTIONARY.ADMIN, USER_PERMISSIONS_DICTIONARY.MANAGE_SCANS];
export const EXPORT_PERMISSION = [USER_PERMISSIONS_DICTIONARY.ADMIN, USER_PERMISSIONS_DICTIONARY.EXPORT_REPORT];
export const DASHBOARD_PERMISSION = [USER_PERMISSIONS_DICTIONARY.ADMIN, USER_PERMISSIONS_DICTIONARY.VIEW_DASHBOARD];

export const URECORDER_DOWNLOAD_URL = "https://check1st.com/downloads/urecorder.zip";
import React, { useState, useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { TRIAL } from 'utils/constants'
import { getAuthHeader } from 'utils/auth'
import { useUser } from 'context/user-context'

const PaymentContext = React.createContext()

function PaymentProvider(props) {
	const user = useUser();
    const [isPaymentModalOpen, setModalOpen] = useState(false);
    const [paymentModalTrigger, setPaymentModalTrigger] = useState(null);
	const isTrialAccount = user.account_type === TRIAL;
	const isCanceledPayg = user.payg_subscription_status == "canceled";

	const [
		{ data: trialConfiguration, error: trialConfigurationError }, getConfiguration] = useAxios(
		{
		  method: 'GET',
		  url: `/trial/configuration`,
		  headers: getAuthHeader()
		},
		{ manual: true }
	  )

	const setPaymentModalOpen = (state, trigger) => {
		setModalOpen(state);
		trigger && setPaymentModalTrigger(trigger);
	}

	useEffect(() => {
		isTrialAccount && getConfiguration();
	}, [])

	return <PaymentContext.Provider value={{isPaymentModalOpen, setPaymentModalOpen, isTrialAccount, isCanceledPayg, trialConfiguration, paymentModalTrigger}} {...props}/>
}

function usePayment() {
	const context = useContext(PaymentContext)
	if (context === undefined) {
		throw new Error(`usePayment must be used within a PaymentProvider`)
	}
	return context
}

export { PaymentProvider, usePayment }

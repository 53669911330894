import { getUser } from './auth'

const initialData = { user: null, projects: [], config: {} }

async function bootstrapAppData() {
	try {
		const data = await getUser()
		if (!data) {
			return initialData
		}
		const { user, accounts, config, projects } = data
		return {
			user: user.data,
			accounts: accounts.data,
			config: config.data,
			projects: projects.data
		}
	} catch (error) {
		return initialData
	}
}

export { bootstrapAppData }

import React from 'react'
import { useAuth } from './auth-context'

const AccountContext = React.createContext()

function AccountProvider(props) {
	const { accounts } = useAuth()
	return <AccountContext.Provider value={accounts} {...props} />
}

function useAccount() {
	const context = React.useContext(AccountContext)
	if (context === undefined) {
		throw new Error(`useAccount must be used within a AccountProvider`)
	}
	return context
}

export { AccountProvider, useAccount }
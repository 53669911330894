export const layout = {
  topHeaderHeight: 70,
  sideNavWidth: 271
};

export const colors = {
  brand: '#2699FB',
  brandLight: '#F0F3F7',
  brandWhite: '#fff',
  brandGray: '#9EA0A5',
  brandDark: '#252F4A',
  brandText: '#00274f',
  brandTextLight: '#778CA2',
  brandButton: '#253f90ff',
  brandLink: '#2699FB',
  brandLinkHover: '#1b3176',
  brandBorderColor: '#E4E9EF',
  success: { color: '#25A032', background: '#D3EDD6', hover: '#72c391' },
  error: { color: '#E40D0D', background: '#ffdada', hover: '#f28e8e' },
  info: { color: '#0057BE', background: '#D2E0F2' },
  warning: { color: '#F69A2E' },
  iconSuccess: { color: '#18d26b', background: 'rgba(24, 210, 107, 0.1)' },
  iconError: { color: '#ff3f3f', background: 'rgba(255, 63, 63, 0.1)' },
  iconInfo: { color: '#0080ff', background: 'rgba(0, 128, 255, 0.1)' },
  iconWarning: { color: '#ffa800', background: 'rgba(255, 168, 0, 0.1)' },
  iconNoChange: { color: '#737373', background: '#f3f3f3' }
};
export const elements = {
  brandBorder: `1px solid ${colors.brandBorderColor}`,
  brandBorderRadius: 6,
  brandBoxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.02)'
};
export const severityColors = {
  low: '#2980b9',
  medium: '#e67e22',
  high: '#e74c3c',
  suggestion: '#2ecc71'
};

export const compareDiffColors = {
  regression: colors.warning.color,
  fixed: colors.success.color,
  error: colors.error.color,
  same: '#222'
};

export const tags = {
  inclusive: colors.success,
  exclusive: colors.error
};

import React, { useState } from 'react'
import { Carousel } from 'antd'
import styled from '@emotion/styled'
import administration from 'style/images/administration.svg'
import dashboard from 'style/images/dashboard.svg'
import finecontrol from 'style/images/finecontrol.svg'
import integrations from 'style/images/integrations.svg'
import webflows from 'style/images/webflows.svg'
import { colors } from 'style/theme'

const StyleCarousel = styled(Carousel)`
  .slick-dots.slick-dots-bottom {
    position: static;
    margin-top: 10px;

    button {
      background: gray;
      width: 30px;
      height: 10px;
      border-radius: 2px;
    }

    .slick-active button {
      background: #2747a8 !important;
      width: 40px;
    }
  }
`

const CarouselElement = styled.div`
  width: 90%;
  margin: 0 auto;
  background: #efefef;
  height: 300px;
  border-radius: 5px;
  display: grid;
  padding: 10px;
  grid-template-columns: 2fr 1fr;
  img {
    height: 280px;
  }
`

const ContentBox = styled.div``

const Title = styled.div`
  color: ${colors.brandButton};
  font-size: 1.6rem;
  font-width: 800;
`

const Content = styled.div`
  color: #000;
  font-size: 1rem;
  font-width: 500;
`
const FeaturesStep = () => {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: 15, fontWeight: 700, marginBottom: 0 }}>Check out other features you're missing</p>
        <p style={{ fontSize: 13 }}>without a paid account</p>
      </div>
      <StyleCarousel autoplay>
        <div>
          <CarouselElement>
            <img src={dashboard} />
            <ContentBox>
              <Title>Dashboard</Title>
              <Content>See how your website's accessibility improved over the time</Content>
            </ContentBox>
          </CarouselElement>
        </div>
        <div>
          <CarouselElement>
            <img src={administration} />
            <ContentBox>
              <Title>Administration</Title>
              <Content>Manage access & permissions and create projects to separate teams</Content>
            </ContentBox>
          </CarouselElement>
        </div>
        <div>
          <CarouselElement>
            <img src={finecontrol} />
            <ContentBox>
              <Title>Fine Control</Title>
              <Content>
                A/B compare to find regressions, filter results by different properties and export results
              </Content>
            </ContentBox>
          </CarouselElement>
        </div>
        <div>
          <CarouselElement>
            <img src={integrations} />
            <ContentBox>
              <Title>Integrations</Title>
              <Content>
                Report to 3rd parties via web hooks, use our flexible rest api, and install Jenkins plugin for CI/CD
              </Content>
            </ContentBox>
          </CarouselElement>
        </div>
        <div>
          <CarouselElement>
            <img src={webflows} />
            <ContentBox>
              <Title>Web Flows</Title>
              <Content>Record & validate SPAs, run pre scan web flows to automate login</Content>
            </ContentBox>
          </CarouselElement>
        </div>
      </StyleCarousel>
    </>
  )
}

export default FeaturesStep

import React from 'react'
import styled from '@emotion/styled'
import { Link } from '@reach/router'
import { Icon } from 'antd'
import { colors } from 'style/theme'

const StyledAppMenuListItem = styled.li({
  cursor: 'pointer',
  position: 'relative'
})

const ListItemLink = styled.span({
  a: {
    display: 'flex',
    alignItems: 'center',
    width: '100%;',
    color: '#A4B2C1',
    height: 64,
    fontSize: 14,
    fontWeight: 500,
    borderRight: 'none',
    position: 'relative',
    transition: 'all 200ms',
    '&.active': {
      // background: colors.brandLight,
      color: colors.brand,
      '&:before': {
        height: '100%',
        background: colors.brand
      }
    },
    i: {
      fontSize: 22,
      width: 68,
      textAlign: 'center'
    },
    '&:before': {
      content: "''",
      width: 3,
      height: 0,
      position: 'absolute',
      transition: 'all 500ms',
      left: 0
    }
  }
})

const SubMenuItems = styled.ul({
  paddingLeft: 70
})

const SubMenuItem = styled.li({
	a: {
		display: 'flex',
		alignItems: 'center',
		width: '100%;',
		color: '#A4B2C1',
		height: 34,
		fontSize: 13,
		fontWeight: 500,
		borderRight: 'none',
		position: 'relative',
		transition: 'all 200ms',
		'&.active': {
			// background: colors.brandLight,
			color: colors.brand
		}
	}
})


const StyledMailToLink = styled.a({
  height: '3.2rem !important',
  display: 'flex',
  alignItems: 'center',
  width: '100%;',
  fontSize: 14,
  fontWeight: 500,
  background: '#2699FB',
  color: "white !important"
})


const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent, isPartiallyCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent || isPartiallyCurrent ? 'active' : ''
      }
    }}
  />
)

const MenuItem = ({ text, path, icon, submenu, mailTo, isTrialAccount, setPaymentModalOpen }) => {
  return (
    <StyledAppMenuListItem>
      <ListItemLink>
        {
          mailTo 
          ?
            <StyledMailToLink href={path}>
              <Icon type={icon} /> 
              <span>{text}</span>
            </StyledMailToLink>
          :
          isTrialAccount
          ?
            <a onClick={() => setPaymentModalOpen(true, text)}>
              <Icon type={icon} />
              <span>{text}</span>
            </a>
          :
          <NavLink to={path}>
            <Icon type={icon} />
            <span>{text}</span>
          </NavLink>
        }
        
      </ListItemLink>
      {submenu && (
        <SubMenuItems>
          {submenu.map(({ text, path }) => {
            return (
              <SubMenuItem key={path}>
                <NavLink to={path}>
                  <span>{text}</span>
                </NavLink>
              </SubMenuItem>
            )
          })}
        </SubMenuItems>
      )}
    </StyledAppMenuListItem>
  )
}

export default MenuItem

import { apiClient, UT_Client } from './api-client'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, DASHBOARD_STORAGE_KEY } from 'utils/constants'
import { PROJECT_STORE_KEY } from 'context/project-context'

function setUser(response) {
	const { access_token, refresh_token, account_role } = response.data
	window.localStorage.setItem(ACCESS_TOKEN_KEY, access_token)
	window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token)
	return { access_token, account_role }
}

function unsetUser() {
	window.localStorage.removeItem(ACCESS_TOKEN_KEY)
	window.localStorage.removeItem(REFRESH_TOKEN_KEY)
	window.localStorage.removeItem(DASHBOARD_STORAGE_KEY);
}

async function getUser() {
	try {
		const token = getToken()
		if (!token) {
			return null
		}
		let currentProject = JSON.parse(localStorage.getItem(PROJECT_STORE_KEY));
		let meBody = currentProject ? {"project_name": currentProject.name } : {};
		const userReq = apiClient('/account/me', { body: meBody, headers: getAuthHeader() })
		const accountsReq = apiClient('/account/list', { headers: getAuthHeader() })
		const projectsReq = apiClient('/projects/names', { headers: getAuthHeader() })
		const configReq = apiClient('/enums')
		const [user, accounts, config, projects] = await Promise.all([userReq, accountsReq, configReq, projectsReq])
		return { user, accounts, config, projects }
	} catch (error) {
		await logout()
		throw new Error(error)
	}
}

function login({ email, password }) {
	return UT_Client('/account/sign-in', { method: 'post', data: { email, password } }).then(setUser)
}

function logout() {
	unsetUser()
	window.location = `/sign-in?return_url=${window.location.pathname}${window.location.search}`
	return Promise.resolve()
}

function getToken() {
	return window.localStorage.getItem(ACCESS_TOKEN_KEY)
}

function getAuthHeader() {
	return { Authorization: `Bearer ${getToken()}` }
}

export { login, logout, setUser, getUser, getToken, getAuthHeader }
import styled from '@emotion/styled'
import { Select } from 'antd'

export const StyledSelect = styled(Select)(({ fullWidth = true }) => ({
	width: fullWidth ? '100%' : 'auto',
	'.ant-select-selection': {
		background: 'none',
		border: 'none',
		borderRadius: 0,
	}
}))

import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { getAuthHeader } from 'utils/auth'
import { CREDITS_PULL_INTERVAL } from '../../utils/constants'
import { colors } from 'style/theme'
import useAxios from 'axios-hooks'
import { Popover, Table } from 'antd'
import { NoData, TableContainer } from 'components'
import CreditsListColumns from './CreditsListColumns'
import { formatNumberIntl, uid } from 'utils/formatters'
import { hasPermission } from 'utils/hasPermission'
import { useProjects } from 'context/project-context'

const StyledCredits = styled.span(props => ({
  display: 'inline-block',
  "margin-right": '20px',
  paddingLeft: 20,
  borderLeft: '1px solid #E4E9EF',
  strong: {
    marginLeft: 7,
    color: colors.brand,
    cursor: props.clickable ? 'pointer' : 'auto'
  }
}))

const StyledCreditsList = styled.div({
  minWidth: 600,
  maxHeight: 400,
  overflowY: 'auto',
  '.ant-table-small, .ant-table-thead > tr > th, .ant-table-tbody > tr > td': {
    border: 'none'
  }
})

export const CreditList = ({ data, loading, managementMode = true}) => {
  const project = useProjects()
  const renderCreditsTable = () => {
    return (
      data &&
      !loading && (
        <Table
          rowKey={record => record.scan_id + uid()}
          pagination={managementMode ? { hideOnSinglePage: true } : false}
          columns={CreditsListColumns({linkToScanSettings: !managementMode}, project)}
          dataSource={data}
          loading={loading}
          size={managementMode ? 'large' : 'small'}
          locale={{
            emptyText: <NoData />
          }}
        />
      )
    )
  }
  return managementMode ? (
    <TableContainer>{renderCreditsTable()}</TableContainer>
  ) : (
    <StyledCreditsList>{renderCreditsTable()}</StyledCreditsList>
  )
}

export const Credits = ({ user }) => {
  const canSeeCreditsDetails = hasPermission([user.account_role], ['admin'])
  const [{ data: creditsResponse, loading: creditsLoading, error: creditsError }, getCurrentCredits] = useAxios(
    {
      url: `/account/credits`,
      method: 'GET',
      headers: getAuthHeader()
    },
    { manual: true }
  )

  useEffect(() => {
    getCurrentCredits()
    let interval = setInterval(() => {
      getCurrentCredits({ timeout: CREDITS_PULL_INTERVAL }).catch(() => {});
    }, CREDITS_PULL_INTERVAL)
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  const renderCredits = () => <strong>{formatNumberIntl(creditsResponse?.current)}</strong>
  return (
    <StyledCredits clickable={canSeeCreditsDetails} id="accountCredits">
      <label>CREDITS:</label>
      {canSeeCreditsDetails && (
        <Popover
          content={<CreditList managementMode={false} data={creditsResponse?.items} loading={creditsLoading} />}
          trigger="click"
          placement="topLeft"
        >
          {renderCredits()}
        </Popover>
      )}
      {!canSeeCreditsDetails && renderCredits()}
    </StyledCredits>
  )
}

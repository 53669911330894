import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Avatar, Icon, Select, Button } from 'antd'
import { colors } from '../../style/theme'
import { LogoutOutlined } from '@ant-design/icons'

const { Option } = Select

const AvatarUser = styled.span({
  textTransform: 'capitalize',
  fontSize: 12,
  fontWeight: 700
})

const Menu = styled.div({
  display: 'grid',
  gridTemplateRows: '30px auto auto auto'
})

const UserArea = styled.div({
  display: 'grid',
  gridTemplateColumns: '30px auto',
  gridGap: '0.8rem'
})

const StyledSelect = styled(Select)({
  margin: '1.4rem 0 0.6rem 0'
})

const AccountName = styled.div({
  fontWeight: 700,
  margin: '1.4rem 0 0.6rem 0'
}) 

const NameAndRole = styled.div({
  display: 'grid',
  gridTemplateRows: 'auto auto',
  width: 200
})

const Name = styled.div({
  fontWeight: 800,
  fontSize: '0.9rem'
})

const Role = styled.div({
  color: colors.brandGray,
  fontWeight: 600,
  fontSize: '0.6rem',
  textTransform: 'uppercase'
})

const Logout = styled.div({
  color: colors.brandLink,
  textTransform: 'uppercase'
})

const LogoutButton = styled(Button)({
  fontSize: '0.7rem',
  textTransform: 'uppercase',
  paddingLeft: '0.6rem'
})

const Caret = styled(Icon)({
  color: colors.brandGray,
  marginLeft: 10
})

export const UserSettings = ({ user }) => {
  return (
    <Fragment>
      <Avatar size={30} style={{ color: colors.brandWhite, backgroundColor: '#e39f30' }}>
        <AvatarUser>
          {user.name.charAt(0)}
          {user.surname.charAt(0)}
        </AvatarUser>
      </Avatar>
      <Caret type="caret-down" />
    </Fragment>
  )
}

export const UserSettingsMenu = ({ user, accounts, changeAccount, logout }) => {
  let currentAccount = accounts.filter(account => account.current)[0]
  return (
    <Menu>
      <UserArea>
        <Avatar size={30} style={{ color: colors.brandWhite, backgroundColor: '#e39f30' }}>
          <AvatarUser>
            {user.name.charAt(0)}
            {user.surname.charAt(0)}
          </AvatarUser>
        </Avatar>
        <NameAndRole>
          <Name>
            {user.name} {user.surname}
          </Name>
          <Role>{user.account_role}</Role>
        </NameAndRole>
      </UserArea>
      {
        accounts.length > 1 ? <StyledSelect defaultValue={currentAccount.id} onChange={changeAccount}>
        {accounts.map((account) => (
          <Option key={account.id} value={account.id}>
            {account.name}
          </Option>
        ))}
      </StyledSelect> : <AccountName>{accounts[0].name}</AccountName>
      }
      <Logout>
        <LogoutOutlined />
        <LogoutButton type="link" onClick={logout}>
          logout
        </LogoutButton>
      </Logout>
    </Menu>
  )
}

import React, { useState } from 'react'
import styled from '@emotion/styled';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { Card, Col, Row } from 'antd';
import {visaIcon, masterCardIcon, discoverIcon, americanExpressIcon} from './icons';

const Container = styled.div`
    display: grid;
    grid-gap: 20px;
`

const PricingCard = styled(Card)`
    border: ${props => `1px solid ${props.color}`};
    border-radius: 5px;
    box-shadow: -6px 6px 18px -7px;

    .ant-card-head{
        background: ${props => props.color}; 
        min-height: 0;
        color: white;

        .ant-card-head-wrapper{
            height: 40px;
        }
    }
`
const TiersInfo = styled.p`
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 2rem;
    text-align: center;
`

const ChargeStep = ({setPaymentModalOpen, tiersData, stripePK}) => {
    
    const stripePromise = loadStripe(stripePK.stripe_pk);

    const pricingColors = ['#2747A8', 'rgb(225, 108, 47)', 'rgb(124, 47, 225)'];
    const removeZeroStarter = (price) => {
        const priceAsString = price.toString();
        const firstDigit = Number(priceAsString[0]);
        if(!firstDigit){
            price = priceAsString.substring(1);
        }
        return price;
    }
    return(
        <Container id="paymentStep">
            <div>
                <div style={{fontSize: 16, color: 'gray', marginBottom: 10}}>PRICING TIERS</div>
                <Row gutter={16}>
                    {
                        tiersData && tiersData.map((item, index) => (
                        <Col span={8}>
                            <PricingCard bordered={true} 
                            title={
                                <div style={{textAlign: 'center'}}>
                                    <span style={{fontSize: 25, fontWeight: 700}}>$</span>
                                    <span style={{fontSize: 25, fontWeight: 700}}>{removeZeroStarter(item.price_per_page)}</span>
                                    <span style={{fontSize: 10, marginLeft: 2}}>PER PAGE</span>
                                </div>
                            }
                            color={pricingColors[index]}>
                                <span style={{fontSize: 14}}>{index === 2 ? `ABOVE` : 'BELOW '} {item.pages/1000}K PAGES per month</span>
                            </PricingCard>
                        </Col>
                        ))
                    }
                </Row>
                <TiersInfo>With Pay-as-you-go Check1st subscription you will be charged each 30 days based upon your usage. <br />**NO additional fees will be applied</TiersInfo>
            </div>
            <Row>
                <Col span={12}>
                    <div style={{fontSize: 16, color: 'gray', marginBottom: 10}}>CREDIT CARD DETAILS</div>
                </Col>
                <Col span={12}>
                    <div style={{textAlign: 'right'}}>
                        <img style={{marginRight: 5}} src={visaIcon} height="35" />
                        <img style={{marginRight: 5}} src={masterCardIcon} width="45" />
                        <img style={{marginRight: 5}} src={discoverIcon} width="45" />
                        <img style={{marginRight: 5}}src={americanExpressIcon} width="45" />
                    </div>
                </Col>
            </Row>
            <Elements stripe={stripePromise}>
                <CheckoutForm setPaymentModalOpen={setPaymentModalOpen}/>
            </Elements>
        </Container>
    )
}

export default ChargeStep;
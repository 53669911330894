import React, { useContext, createContext } from 'react'
import { useAuth } from './auth-context'

export const PROJECT_STORE_KEY = 'ut_project'
const ProjectContext = createContext()

function ProjectProvider({ children }) {
  const { projects } = useAuth()
  let project;
  let defaultProject = projects.filter((p) => p.name === "Default")[0] || projects[0];
  try {
    project = JSON.parse(localStorage.getItem(PROJECT_STORE_KEY))
    if (!project) {
      project = defaultProject
    }
  } catch (e) {
    project = defaultProject
  }

  const handleProjectChange = project => {
    localStorage.setItem(PROJECT_STORE_KEY, JSON.stringify(project))
    window.location.href = "/scan-settings"
  }

  return (
    <ProjectContext.Provider value={{ projects: projects, project, setProject: handleProjectChange }}>
      {children}
    </ProjectContext.Provider>
  )
}

function useProjects() {
  const context = useContext(ProjectContext)
  if (context === undefined) {
    throw new Error(`useProject must be used within a ProjectProvider`)
  }
  return context
}

export { ProjectProvider, useProjects }

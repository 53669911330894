import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'antd'

const StyledNoData = styled.div({
	textAlign: 'center'
})

const StyledNoDataIcon = styled(Icon)(props => ({
	fontSize: props.size,
	color: props.color,
	marginBottom: 7
}))

const Text = styled.div(props => ({
	color: props.color
}))

export const NoData = ({ text = 'NO DATA', icon = 'file', color = 'rgba(0,0,0, .19)', size = 30 }) => (
	<StyledNoData>
		<StyledNoDataIcon type={icon} color={color} size={size} />
		<Text color={color}>{text}</Text>
	</StyledNoData>
)

import React from 'react'
import styled from '@emotion/styled'
import { Skeleton } from 'antd'
import { AutoSizer } from 'react-virtualized'
import { Pie } from '@nivo/pie'
import { severityColors } from 'style/theme'

const StyledChart = styled.div(({height})=> ({
	height: height
}))

const { low, medium, high, suggestion } = severityColors

const severityChartProps = {
	margin: { top: 0, right: 0, bottom: 0, left: 0 },
	enableRadialLabels: false,
	padAngle: 3,
	innerRadius: 0.7,
	enableSlicesLabels: false,
	tooltip: ({ label, color }) => <strong style={{ color }}>{label}</strong>,
	theme: {
		tooltip: {
			container: {
				fontSize: 'inherit',
				borderRadius: '2px',
				boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
				padding: '5px 9px'
			}
		}
	}
}

let chartData = [
	{
		id: 'low',
		label: 'LOW',
		value: 0,
		color: low
	},
	{
		id: 'suggestion',
		label: 'SUGGESTION',
		value: 0,
		color: suggestion
	},
	{
		id: 'medium',
		label: 'MEDIUM',
		value: 0,
		color: medium
	},
	{
		id: 'high',
		label: 'HIGH',
		value: 0,
		color: high
	}
]

export const ScanSeverity = ({ height='85%', data }) => {
	let d = chartData;
	let severity = data;
	var colorsArr = [];
	if(severity) {
		d = Object.entries(severity).map(([key, value]) => {
			let type = d.find(item => item.id === key.toLowerCase())
			if(type)
				type.value = value
			return type
		})

		colorsArr = d.map((c) => c.color);
	}
	if(!data) return <Skeleton active paragraph={false} />
	return (
		<StyledChart height={height}>
			<AutoSizer>
				{({ height, width }) => (
					<Pie
						colors={colorsArr}
						height={height}
						width={width}
						data={d}
						{...severityChartProps}
					/>
				)}
			</AutoSizer>
		</StyledChart>
	)
}

import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import axios from 'axios'
import { ACCESS_TOKEN_KEY } from 'utils/constants'
import { logout } from 'utils/auth'
import { navigate } from '@reach/router'
import {notificationToast} from 'components/NotificationToast';

const CORE_SERVER = process.env.REACT_APP_API_ADDRESS

export const UT_Client = axios.create({
	baseURL: CORE_SERVER,
	timeout: 200000000
})

UT_Client.interceptors.response.use(
	function(response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response
	},
	function(error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error

		let status = error && error.response ? error.response.status : 404;

		if (status === 401) {
			return logout()
		}
		if (status === 402) {
			if (!window.location.href.includes("/payment-required"))
				navigate('/payment-required')
			return Promise.reject(error)
		}
		else if(status === 403){
			notificationToast({
				message: 'Error',
				description: `unautorized access`,
				icontype: 'alert'
			  })
			  return Promise.reject(error)
		} 
		else {
			return Promise.reject(error)
		}
	}
)

export const apiClient = (endpoint, { body, ...customConfig } = {}) => {
	const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY)
	const headers = { 'content-type': 'application/json' }
	if (accessToken) {
		headers.Authorization = `Bearer ${accessToken}`
	}
	const config = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers
		}
	}
	if (body) {
		config.data = body
	}
	return UT_Client(endpoint, config)
}

export const cache = new LRU({ max: 5, maxAge: 5000 })

configure({ axios: UT_Client, cache })

import React from 'react'
import MenuItem from 'components/AppMenu/MenuItem'
import { Location } from '@reach/router'

const AdministrationSubMenu = [
  { text: 'DOMAINS', path: '/administration/account/domains' },
  { text: 'CREDITS', path: '/administration/account/credits' },
  { text: 'SETTINGS', path: '/administration/account/settings' }
]

const AdministrationMenu = () => {
  return (
    <Location>
      {({ location }) => {
        let subMenu = []
        let pathnameParams = location?.pathname && location.pathname.split('/')
        if (pathnameParams && pathnameParams.length > 3 && pathnameParams[1] == 'administration') {
          subMenu = AdministrationSubMenu.map(item => {
            pathnameParams.splice(3, 1, item.path)
            let subPath = pathnameParams.slice(3).join('/')
            return { text: item.text, path: subPath }
          })
        }
        return (
          <MenuItem
            text="ADMINISTRATION"
            icon="audit"
            path="/administration/account"
            location={location}
            submenu={subMenu}
          />
        )
      }}
    </Location>
  )
}

export default AdministrationMenu

import React, { createContext, useContext } from 'react';
import styled from '@emotion/styled';
import { colors } from 'style/theme';

const StyledPills = styled.ul({
  display: 'flex',
  justifyContent: 'space-around',
  marginLeft: 'auto'
});

const StyledPill = styled.li(({ active }) => ({
  backgroundColor: active ? colors.brand : 'none',
  color: active ? colors.brandWhite : colors.brandGray,
  fontSize: 12,
  borderRadius: 50,
  cursor: 'pointer',
  padding: '3px 10px',
  transition: 'all 300ms ease-in-out',
  marginLeft: '2px'
}));

const PillsContext = createContext();

function usePills() {
  const context = useContext(PillsContext);
  if (context === undefined) {
    throw new Error(`usePills must be used within a PillsProvider`);
  }
  return context;
}

export const Pills = ({ value, onChange, children, ...rest }) => {
  return (
    <PillsContext.Provider value={{ selectedValue: value, onChange, ...rest }}>
      <StyledPills role="radiogroup" {...rest}>
        {children}
      </StyledPills>
    </PillsContext.Provider>
  );
};

export const Pill = ({ children, value }) => {
  const pills = usePills();
  const { selectedValue, onChange } = pills;
  const isActive = selectedValue === value;
  return (
    <StyledPill active={isActive} onClick={() => onChange(value)}>
      {children}
    </StyledPill>
  );
};

import React, { useState } from 'react'
import { Button, Modal, Steps } from 'antd'
import styled from '@emotion/styled'
import { URECORDER_DOWNLOAD_URL } from 'utils/constants'
import { usePayment } from 'context/payment-context';

const { Step } = Steps;

const DownloadButton = styled(Button)`
font-weight: 800;
border-left: 1px solid #E4E9EF;
height: 30px;
display: inline-block;
:hover{
    border-left: 1px solid #E4E9EF; 
}
`;

const DownloadInlineButton = styled(Button)`
font-weight: 800;
border-left: 1px solid #E4E9EF;
height: 30px;
padding: 0;
display: inline-block;
:hover{
    border-left: 1px solid #E4E9EF; 
}
`

const DownloadButtonInModal = styled(Button)({
    fontSize: '1rem',
    fontWeight: 800,
    padding: 0
});

const LinkButton = styled(Button)({
    fontWeight: 800,
    padding: 0,
    fontSize: '0.8rem'
});


export const UrecorderDownload = ({ triggerText }) => {
    const { isTrialAccount, setPaymentModalOpen } = usePayment();
    const [downloadDialogVisible, setDownloadDialogVisible] = useState(false);

    const openDialog = (state) => isTrialAccount ? setPaymentModalOpen(true, "uRecorder") : setDownloadDialogVisible(state);


    return (
        <>
            {
                triggerText
                    ?
                    <DownloadInlineButton data-id="urecorderSownloadButton" type="link" onClick={openDialog}>{triggerText}</DownloadInlineButton>
                    :
                    <DownloadButton data-id="urecorderSownloadButton" type="link" icon="download" onClick={openDialog}>Get uRecorder</DownloadButton>
            }

            <Modal
                centered
                width={600}
                title="uRecorder installation instructions"
                visible={downloadDialogVisible}
                onCancel={() => openDialog(false)}
                footer={[]}
            >
                <Steps direction="vertical" current={5}>
                    <Step title={<DownloadButtonInModal type="link" href={URECORDER_DOWNLOAD_URL} target="_blank" title="download urecorder">Download uRecorder</DownloadButtonInModal>} />
                    <Step title={<span><span>Install </span> <LinkButton type="link" href="https://nodejs.org/en/" target="_blank" title="NodeJS">NodeJS</LinkButton> LTS</span>} />
                    <Step title="Setup using setup.bat for Windows and setup.sh for Linux/MacOS." />
                    <Step title="Run using urecoder.bat for Windows and urecoder.sh for Linux/MacOS." />
                    <Step title="Done." />
                </Steps>
            </Modal>
        </>
    )
}
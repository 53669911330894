import React from 'react'
import styled from '@emotion/styled'
import { Spin } from 'antd'
import { ReactComponent as SpinIcon } from '../../style/icons/loading.svg'

const FullPageOverlay = styled.div({
	width: '100%',
	height: '100%',
	position: 'absolute',
	zIndex: 1010,
	left: 0,
	top: 0,
	backgroundColor: 'rgba(255,255,255,0.85)'
})

const SpinnerOverlay = styled.div({
	position: 'absolute',
	zIndex: 1020,
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)'
})

const indicator = (
	<span>
		<SpinIcon width={64} height={64} />
	</span>
)
Spin.setDefaultIndicator(indicator)

export const Spinner = props => <Spin {...props} />

export const FullPageSpinner = props => (
	<FullPageOverlay>
		<SpinnerOverlay>
			<Spinner {...props} />
		</SpinnerOverlay>
	</FullPageOverlay>
)

export const PageLoading = props => (
		<SpinnerOverlay>
			<Spinner {...props} />
		</SpinnerOverlay>
)

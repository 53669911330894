import React, { lazy, useEffect } from 'react'
import { Global } from '@emotion/core'
import globalStyle from './style/global'
import { useUser } from './context/user-context'
import { FullPageSpinner } from 'components'
import smartlookClient from 'smartlook-client'

const loadAuthenticatedApp = () => import('./authenticated-app')
const AuthenticatedApp = lazy(loadAuthenticatedApp)
const UnauthenticatedApp = lazy(() => import('./unauthenticated-app'))

function App() {
  const user = useUser()
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  useEffect(() => {
    loadAuthenticatedApp()
    smartlookClient.init('c12aba6ec10db73e8affc2d09df1bdf42cb5dfdf')
  }, [])

  useEffect(() => {
    if (user) {
      smartlookClient.identify(user.user_id, {
        name: `${user.name} ${user.surname}`,
        email: user.email
      })
    }
  }, [user])

  const isSignupFrame = window.location.pathname === '/create-account-frame'

  return (
    <React.Suspense fallback={<FullPageSpinner delay={500} />}>
      <Global styles={globalStyle} />
      {user && !isSignupFrame ? <AuthenticatedApp user={user} /> : <UnauthenticatedApp />}
    </React.Suspense>
  )
}

export default App

import React from 'react'
import styled from '@emotion/styled'
import { AppMenu } from 'components'
import { colors, layout } from 'style/theme'

const StyledSideBar = styled.aside({
	width: layout.sideNavWidth,
	background: colors.brandDark,
	minHeight: '100vh',
	position: 'fixed',
	zIndex: 1000
})

export const SideBar = ({user}) => {
	return (
		<StyledSideBar>
			<AppMenu user={user} />
		</StyledSideBar>
	)
}

import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'style/theme'

const StyledFromTotal = styled.div({
	alignSelf: 'center',
	fontWeight: 700
})

const StyledTotal = styled.span({
	color: colors.brandTextLight
})

export const CurrentFromTotal = ({ current, total }) => {
	return (
		<StyledFromTotal>
			<span>{current}/</span>
			<StyledTotal>{total}</StyledTotal>
		</StyledFromTotal>
	)
}

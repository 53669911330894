import React from 'react'
import { Link } from '@reach/router'
import styled from '@emotion/styled'
import moment from 'moment'
import { colors } from 'style/theme'
import TimeAgo from 'react-timeago'
import { toLocalTime, formatNumberIntl } from 'utils/formatters'

const CreditsCol = styled.div(props => ({
  color: props.credits === 0 ? colors.brandText : props.credits > 0 ? colors.success.color : colors.error.color,
  fontWeight: 800,
  fontSize: '0.8rem'
}))

const StyledName = styled.span({
  fontWeight: 700,
  fontSize: '0.9rem',
  color: colors.brandText
})

const StyledEmail = styled.span(props => ({
  fontWeight: 500,
  fontSize: props.linkToScanSettings ? '0.7rem' : '0.9rem',
  color: colors.brandTextLight
}))

const StyledDate = styled.span(props => ({
  fontWeight: 700,
  fontSize: props.linkToScanSettings ? '0.7rem' : '0.8rem',
  color: colors.brandTextLight
}))

const StyledLink = styled(Link)({
  fontWeight: 700,
  fontSize: '0.8rem'
})

function CreditsListColumns({ linkToScanSettings = false }, myProject) {
  return [
    {
      title: 'CREDITS',
      dataIndex: 'credits',
      width: linkToScanSettings ? '15%' : '8%',
      render(credits) {
        return (
          <CreditsCol credits={credits}>
            {credits === 0 ? '' : credits > 0 ? '+' : ''}
            {formatNumberIntl(credits)}
          </CreditsCol>
        )
      }
    },
    {
      title: 'SCAN SETTINGS',
      dataIndex: 'scan_settings',
      render(scan_settings, record) {
        return linkToScanSettings && record.project_id == myProject.project.id && record.scan_settings_id ? (
          <StyledLink to={`/scan-settings/${record.scan_settings_id}/scans/`}>{scan_settings}</StyledLink>
        ) : (
          <StyledName>{scan_settings}</StyledName>
        )
      }
    },
    {
      title: 'USER',
      dataIndex: 'user',
      render(user) {
        return (
          <StyledEmail linkToScanSettings={linkToScanSettings}>{user}</StyledEmail>
        )
      }
    },
    {
      title: 'WHEN',
      dataIndex: 'when',
      render(when) {
        let now = moment()
        let created = moment(toLocalTime(when))
        let isNow = now.diff(created, 'minutes') < 1
        return <StyledDate linkToScanSettings={linkToScanSettings}>{isNow ? <span>now</span> : <TimeAgo date={toLocalTime(when)} live={false} />}</StyledDate>
      }
    }
  ]
}

export default CreditsListColumns

import React from 'react'
import styled from '@emotion/styled'
import { colors, elements } from 'style/theme'

const padding = {
	none: 0,
	small: '0.6rem',
	normal: '1rem',
	large: '3rem'
}
const StyledContainer = styled.div(({ pad = 'normal', radius = elements.brandBorderRadius }) => ({
	padding: padding[pad],
	background: colors.brandWhite,
	borderRadius: radius,
	border: elements.brandBorder,
	boxShadow: elements.brandBoxShadow,
	position: 'relative'
}))

const StyledTableContainer = styled.div({
	padding: 0,
	background: colors.brandWhite,
	borderRadius: elements.brandBorderRadius,
	border: elements.brandBorder,
	boxShadow: elements.brandBoxShadow,
	'.ant-table-pagination.ant-pagination': {
		float: 'none',
		margin: '50px 0',
		textAlign: 'center',
		position: 'absolute',
		transform: 'translateX(-50%)',
		width: 'calc(100% - 271px)',
		left: '50%',
		'.ant-pagination-item': {
			backgroundColor: 'transparent',
			border: `none`,
			borderRadius: '50%',
			'&.ant-pagination-item-active a': {
				color: colors.brandWhite
			}
		},
		'.ant-pagination-item-link': {
			backgroundColor: 'transparent',
			border: 'none'
		},
		'.ant-pagination-next, .ant-pagination-prev': {
			'.ant-pagination-item-link': {
				backgroundColor: '#D7DDE3',
				borderRadius: 4
			},
		}
	}
})

export const ContentContainer = ({ children, ...rest }) => <StyledContainer {...rest}>{children}</StyledContainer>
export const TableContainer = ({ children, ...rest }) => (
	<StyledTableContainer {...rest}>{children}</StyledTableContainer>
)

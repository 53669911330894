import styled from '@emotion/styled'
import { Input as BaseInput } from 'antd'
import { colors } from '../../style/theme'

export const Input = styled(BaseInput)({
	fontSize: 14,
	borderRadius: 10,
	'&.error': {
		borderColor: colors.error
	}
})
export const Search = styled(BaseInput.Search)({
	fontSize: 14,
	borderRadius: 10,
})

import { colors } from './theme'
import InterLight from './fonts/Inter-Light-BETA.woff'
import InterRegular from './fonts/Inter-Regular.woff'
import InterMedium from './fonts/Inter-Medium.woff'
import InterSemiBold from './fonts/Inter-SemiBold.woff'
import InterBold from './fonts/Inter-Bold.woff'
import NunitoSansBold from './fonts/NunitoSans-Black.ttf'

export default {
	// eslint-disable-next-line
	'@fontFace': {
		fontFamily: 'Inter',
		fontWeight: 300,
		src: `url(${InterLight}) format('woff')`
	},
	// eslint-disable-next-line
	'@fontFace': {
		fontFamily: 'Inter',
		fontWeight: 400,
		src: `url(${InterRegular}) format('woff')`
	},
	// eslint-disable-next-line
	'@fontFace': {
		fontFamily: 'Inter',
		fontWeight: 500,
		src: `url(${InterMedium}) format('woff')`
	},
	// eslint-disable-next-line
	'@fontFace': {
		fontFamily: 'Inter',
		fontWeight: 600,
		src: `url(${InterSemiBold}) format('woff')`
	},
	// eslint-disable-next-line
	'@fontFace': {
		fontFamily: 'Inter',
		fontWeight: 700,
		src: `url(${InterBold}) format('woff')`
	},
	// eslint-disable-next-line
	'@fontFace': {
		fontFamily: 'Nunito Sans',
		fontWeight: 700,
		src: `url(${NunitoSansBold}) format('ttf')`
	},
	'*': {
		padding: 0,
		margin: 0,
		boxSizing: 'border-box',
		outline: 'none',
		WebkitFontSmoothing: 'antialiased',
		MozOsxFontSmoothing: 'grayscale'
	},
	'::-webkit-scrollbar-track': {
		boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
		borderRadius: 0,
		backgroundColor: '#fbfbfb'
	},

	'::-webkit-scrollbar': {
		width: 3,
		backgroundColor: colors.brand
	},

	'::-webkit-scrollbar-thumb': {
		borderRadius: 0,
		boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
		backgroundColor: colors.brand
	},
	a: {
		textDecoration: 'none',
		color: colors.brandLink,
		transition: 'color 300ms linear',
		':hover': {
			color: colors.brandLinkHover
		}
	},
	'h1,h2,h3,h4,h5,h6': {
		fontWeight: 700,
		padding: 0,
		margin: 0,
		color: colors.brandText
	},
	pre: {
		margin: 0
	},
	'ul, li': {
		margin: 0,
		padding: 0,
		listStyle: 'none'
	},
	body: {
		fontFamily: '"Inter", -apple-system, system-ui, BlinkMacSystemFont, sans-serif, "Inter", Roboto, Ubuntu',
		fontSize: '12px',
		color: colors.text,
		fontWeight: 400,
		background: '#ECF0F5'
	},
	'.ant-modal-footer': {
		padding: '0!important',
		textAlign: 'center!important'
	}
}

import styled from '@emotion/styled'
import { Tag as BaseTag } from 'antd'

export const Tag = styled(BaseTag)({
	textTransform: 'uppercase',
	fontWeight: 700,
	padding: '2px 18px',
	fontSize: 10,
	borderRadius: 4
})

export const SeverityTag = styled(Tag)(props => ({
	fontWeight: 700,
	fontSize: 11,
	borderRadius: 20,
	padding: '4px 10px',
	background: 'none',
	color: props.tagcolor,
	borderColor: props.tagcolor
}))

export const CompareDiffTag = styled(SeverityTag)(props => ({
	color: props.tagcolor,
	borderColor: props.tagcolor
}))


